<template>
    <div class="account">
        <ul class="solid_box">
            <li class="big_solid"></li>
            <li class="big_solid"></li>
            <li class="big_solid"></li>
        </ul>
        <div class="userbox">
            <div class="user_avatar">
                <el-avatar :size="60"
                    src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/logo_circle.png"></el-avatar>
            </div>
            <div class="user_info">
                <div class="username">{{ $store.state.user.account }}</div>
                <div class="timer">{{ $store.state.user.expirationDate }}</div>
                <!-- <div class="team">团队码：214235</div>
                <div class="timer">到期时间：2025.12.23</div> -->
            </div>
        </div>
        <div class="tj">
            <div class="tj_col">
                <div>{{ userInfo.point || "0" }}</div>
                <div>最大可生成视频数</div>
            </div>
            <div class="solid"></div>
            <div class="tj_col">
                <div>{{ num || "0" }}</div>
                <div>已生成视频数</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSource } from "@/api/api";

import { getUSer } from "@/api/user";
export default {
    name: "Account1",

    data() {
        return {
            num: 0,
            sucai_where: {
                projectType: "mev",
                currentPage: 1,
                pageSize: 20,
            },
            userInfo: {}
        };
    },

    mounted() {

        getUSer().then((res) => {
            // console.log(res.data);
            this.userInfo = res.data;
        });
        getSource(this.sucai_where).then((res) => {
            console.log(res);
            this.num = res.data.pagination.total;
        });
    },

    methods: {},
};
</script>

<style lang="less" scoped>
.account {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 20px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
    background: linear-gradient(45deg,
            rgba(169, 251, 255, 0.25) 4%,
            rgba(251, 231, 255, 0.25) 52%,
            rgba(167, 190, 255, 0.25) 100%);
    border: 2px solid #fff;
    border-radius: 8px;

    .solid_box {
        position: absolute;
        top: -10px;
        width: 100%;

        .big_solid {
            position: absolute;
            width: 16px;
            border-radius: 20px;
            transform: rotate(20deg);
            box-shadow: 0 0 6px rgba(221, 221, 221, 0.4);
            transform-origin: top;
            height: 0;
        }

        .big_solid:nth-child(1) {
            left: 40px;
            height: 120px;
            border-radius: 20px;
            background: linear-gradient(224deg,
                    rgba(255, 101, 238, 0.4),
                    rgba(227, 174, 219, 0.4));
            animation: solid1 0.3s linear 0.2s;
        }

        .big_solid:nth-child(2) {
            left: 130px;
            height: 80px;
            background: linear-gradient(rgba(42, 244, 153, 0.397),
                    rgba(8, 177, 233, 0.369));
            animation: solid2 0.3s linear 0.3s;
        }

        .big_solid:nth-child(3) {
            left: 230px;
            height: 180px;
            background: linear-gradient(90deg,
                    rgba(32, 212, 253, 0.358),
                    rgba(61, 122, 255, 0.296));
            animation: solid3 0.3s linear;
        }
    }
}

@keyframes solid1 {
    0% {
        height: 0;
    }

    100% {
        height: 120px;
    }
}

@keyframes solid2 {
    0% {
        height: 0;
    }

    100% {
        height: 80px;
    }
}

@keyframes solid3 {
    0% {
        height: 0;
    }

    100% {
        height: 180px;
    }
}

.userbox {
    // height: 80px;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    z-index: 9;

    .user_avatar {
        line-height: 0%;
    }

    .user_info {
        // padding-left: 16px;
        text-align: center;
        display: flex;
        flex-direction: column;
        // height: 72px;
        overflow: hidden;
        justify-content: center;

        .username {
            font-size: 14px;
            color: #4e5969;
        }

        .team,
        .timer {
            font-size: 12px;
            color: #86909c;
        }
    }
}

.tj {
    display: flex;
    align-items: center;

    .tj_col {
        flex: 1;
        text-align: center;
        color: #86909c;
        font-size: 12px;

        &>div:first-child {
            color: #4e5969;
            font-weight: bold;
            font-size: 14px;
        }
    }

    .solid {
        width: 1px;
        height: 24px;
        background-color: #dcdee2;
    }
}
</style>